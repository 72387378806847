<template>
  <v-snackbar
    v-model="snack"
    centered
    :color="color"
    height="80"
    width="500"
    timeout="5000"
    elevation="9"
  >
    <v-alert v-if="info" :value="snack" type="info">
      {{ snackMessage }}
    </v-alert>
    <v-alert v-if="warning" :value="snack" type="warning">
      {{ snackMessage }}
    </v-alert>
    <v-alert v-if="error" :value="snack" type="error">
      {{ snackMessage }}
    </v-alert>
    <v-alert v-if="success" :value="snack" type="success">
      {{ snackMessage }}
    </v-alert>
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="close"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackInfo",
  props: {
    snackMessage: {
      type: String,
      required: false,
    },
    snackType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      snack: true,
      info: false,
      warning: false,
      error: false,
      success: false,
      color: "blue",
    };
  },
  created() {
    if (this.snackType == "info") {
      this.info = true;
      this.warning = false;
      this.error = false;
      this.success = false;
      this.color = "blue";
    } else if (this.snackType == "warning") {
      this.warning = true;
      this.info = false;
      this.error = false;
      this.success = false;
      this.color = "#fb8c00";
    } else if (this.snackType == "error") {
      this.error = true;
      this.info = false;
      this.warning = false;
      this.success = false;
      this.color = "red";
    } else if (this.snackType == "success") {
      this.success = true;
      this.info = false;
      this.warning = false;
      this.error = false;
      this.color = "green";
    }
  },
  methods: {
    close() {
      this.$emit("onSnackClose");
      this.snack = false;
    },
  },
};
</script>
