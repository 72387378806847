import axios from "axios";
import AuthService from "../service/auth-service";
import MapService from "../service/map-service";

class DataService {
  // In production this calls https://kamux-service-api.azure-api.net/transport-service/sql/orders
  // -> "transport-sql-backend-service" (resource group: KamuxService) logic app
  // Logic app contains all the logic and connects to the SQL Server "kamux-service" database "transport-database_restore"
  // This logic has been implemented to KMS TransportPortalController
  // Since this is deployed to Azure Static Web Apps the connection to APIM must be configured
  // see https://learn.microsoft.com/en-us/azure/static-web-apps/apis-api-management
  async getTransportOrders(service) {
    const response = await axios
      .create({ withCredentials: true })
      .post(
        process.env.VUE_APP_BASE_API_URI + "sql/orders",
        service,
        await this.getHeadersUiBackend()
      );

    let mappedData = [];
    if (typeof response.data.ResultSets.Table1 !== "undefined") {
      mappedData = MapService.mapOrderData(response.data.ResultSets.Table1);
    }

    return mappedData;
  }

  // See the first comment
  async updateTransportOrders(payload) {
    const response = await axios
      .create({ withCredentials: true })
      .post(
        process.env.VUE_APP_BASE_API_URI + "sql/orders",
        payload,
        await this.getHeadersUiBackend()
      );
    return response.data;
  }

  // In production this calls https://kamux-service-api.azure-api.net/transport-service/update
  // -> "transport-update-vehicle" logic app
  // Logic app deployments:
  // transport-update-vehicle / Integration-Dev -> https://api-dev.kamux.auto/external/kms/api/TransportOrder/add
  // transport-update-vehicle / Integrations-Uat -> https://api-uat.kamux.auto/external/kms/api/TransportOrder/add
  // transport-update-vehicle / Integration-Prod -> https://api.kamux.auto/external/kms/api/TransportOrder/add
  async updateTransportOrdersKMS(payload) {
    const response = await axios
      .create({ withCredentials: true })
      .post(
        process.env.VUE_APP_BASE_API_URI + "update",
        payload,
        await this.getHeadersKms()
      );
    return response.data;
  }

  // In production this calls https://kamux-service-api.azure-api.net/transport-service/permissions
  // -> "transport-get-permissions" (resource group: KamuxService) logic app
  // Logic app checks the user's email from the SQL Server "kamux-service" database "transport-database_restore" table "user_permissions"
  // and returns the "transport_company" column data
  // This logic has been implemented to KMS TransportPortalController
  async getPermissions(payload) {
    const response = await axios
      .create({ withCredentials: true })
      .post(
        process.env.VUE_APP_BASE_API_URI + "permissions",
        payload,
        await this.getHeadersUiBackend()
      );

    return response.data;
  }

  // See the first comment
  async getLogs(payload) {
    const response = await axios
      .create({ withCredentials: true })
      .post(
        process.env.VUE_APP_BASE_API_URI + "sql/orders",
        payload,
        await this.getHeadersUiBackend()
      );
    return response.data;
  }

  // *********** Helpers *******************************
  async getHeadersUiBackend() {
    let token = await this.getAPIMToken();

    return new Promise(function (resolve) {
      // reject
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      return resolve(header);
    });
  }

  async getHeadersKms() {
    let token = await this.getAPIMToken();

    return new Promise(function (resolve) {
      // reject
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      return resolve(header);
    });
  }

  async getAPIMToken() {
    let authService = new AuthService();
    let resolvedToken = "";

    await authService.getApiManagementToken().then((token) => {
      resolvedToken = token.accessToken;
    });

    return resolvedToken;
  }
}

export default new DataService();
