<template>
  <v-container>
    <v-app-bar app dark>
      <div class="d-flex align-center">
        Kamux Transport Order Management Platform (v0.94)
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>
      <v-spacer></v-spacer>
      <!-- <span>{{ permissions }}</span> -->
      <v-spacer></v-spacer>
      <span>{{ user }}</span>
      <v-list>
        <v-list-item v-if="user" @click="logout">
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!user" @click="login">
          <v-list-item-title>Log In</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-app-bar>
    <v-main>
      <transport-main />
    </v-main>
    <v-footer app dark>
      <span class="white--text">&copy; {{ date }} Kamux Oy</span>
    </v-footer>
  </v-container>
</template>

<script>
import transportMain from "@/components/transportMain.vue";
import { dataGetters } from "@/store/getters";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    transportMain,
  },
  data: () => ({
    date: "",
  }),
  created() {
    var today = new Date();
    var thisYear = today.getFullYear();
    this.date = thisYear;
  },
  computed: {
    ...mapGetters({
      user: dataGetters.GET_USERNAME,
    }),
  },
  methods: {
    async logout() {
      await this.$AuthService.signOut(this.user);
    },
    async login() {
      await this.$AuthService.signIn();
    },
  },
};
</script>
