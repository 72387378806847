<template>
  <v-card>
    <v-card-title>
      <h2>Delivered cars</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-dialog
        ref="dateRange"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Date range"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          range
          :first-day-of-week="1"
          locale="fi-FI"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancelDates()"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateMenu = false"> OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-card-title>
    <v-data-table
      dense
      v-model="selected"
      :headers="headers"
      :items="transportOrders"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="isLoading"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :sort-by="'DeliveredTime'"
      :sort-desc="true"
      :custom-sort="customSort"
      item-key="Id"
      show-expand
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="green lighten-5">
          <v-container>
            <v-row :colspan="headers.length" no-gutters>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine"><b>Brand:</b> {{ item.Brand }}</div>
                    <div class="oneLine"><b>Model:</b> {{ item.Model }}</div>
                    <!-- <div class="oneLine"><b>VIN:</b> {{ item.Vin }}</div> -->
                    <div class="oneLine"><b>Regno:</b> {{ item.RegNo }}</div>
                    <div class="oneLine">
                      <b>Orig. regno:</b> {{ item.ForeignLicensePlate }}
                    </div>
                    <div class="oneLine">
                      <b>Pickup outlet:</b> {{ item.PickupOutletName }}
                    </div>
                    <div class="oneLine">
                      <b>Delivery address:</b> {{ item.DestinationAddress }}
                    </div>
                    <div class="oneLine">
                      <b>Created by:</b> {{ item.CreateBy }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      elevation="2"
                      x-small
                      @click="ShowHistory(item)"
                      >Show order history</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      elevation="2"
                      x-small
                      @click="ReturnToPrevious(item)"
                      >Cancel to in delivery</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="pickupInformation"
                        label="Kamux pickup information"
                        v-model="item.PickupInformation"
                        disabled
                      ></v-textarea>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="transportNotes"
                        label="Transport notes"
                        v-model="item.TransportInformation"
                        height="120"
                        disabled
                      ></v-textarea>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.Billed`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.Billed"
          large
          persistent
          @save="saveBilledInfo(props.item)"
        >
          <div>{{ props.item.Billed }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Billed</div>
            <v-text-field
              v-model="props.item.Billed"
              :rules="[maxChars]"
              label="Edit"
              single-line
              counter
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <infoSnack
      v-if="noSelection"
      :snackMessage="snackMessage"
      :snackType="snackType"
      @onSnackClose="closeSnackbar"
    />
    <orderHistory
      v-if="historyDialog"
      :history="history"
      @onHistoryClose="closeOrderHistory"
    />
    <confirm
      v-if="confirmVisible"
      :confirmMessage="confirmMessage"
      @onConfirmClose="closeConfirmDialog"
      @onConfirmAccept="ConfirmReturnToPreviousState"
    />
  </v-card>
</template>
<script>
import { dataActions } from "@/store/actions";
import { dataGetters } from "@/store/getters";
import { mapActions, mapGetters } from "vuex";
import { helpersMixin } from "@/mixins/mixins.js";
import infoSnack from "@/components/snackbars/info-snack.vue";
import orderHistory from "@/components/dialogs/order-history.vue";
import confirm from "@/components/dialogs/confirm.vue";

export default {
  mixins: [helpersMixin],
  components: {
    infoSnack,
    orderHistory,
    confirm,
  },
  name: "Delivered",
  data() {
    return {
      maxChars: (v) => !v || v.length <= 200 || "Input too long!",
      dates: [],
      dateMenu: false,
      confirmVisible: false,
      confirmMessage: "",
      historyDialog: false,
      history: {},
      editSingleDialog: false,
      editMultiDialog: false,
      expanded: [],
      single: true,
      selected: [],
      noSelection: false,
      snackMessage: "No transports selected!",
      snackType: "info",
      singleExpand: true,
      search: "",
      dialog: false,
      dialogEditDates: false,
      editedIndex: -1,
      headers: [
        // { text: "Confirm as delivered", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
        {
          text: "id",
          value: "Id",
        },
        // {
        //   text: "Regno",
        //   // align: "start",
        //   value: "RegNo",
        //   sortable: true,
        // },
        { text: "VIN", value: "Vin", sortable: true },
        {
          text: "Delivery time",
          value: "DeliveredTime",
          sortable: true,
          filter: (value) => {
            if (!this.dates) {
              return true;
            }
            let testi = this.parseDate(value);
            let date1 = new Date(testi);
            let date2 = new Date(this.dates[0]);
            let date3 = new Date(this.dates[1]);

            if (date2 == "Invalid Date" && date3 == "Invalid Date") {
              return value;
            } else if (date1 >= date2 && date1 <= date3) {
              return value;
            } else if (date1 >= date2 && date3 == "Invalid Date") {
              return value;
            }
          },
        },
        { text: "Pickup location", value: "PickupAddress", sortable: false },
        { text: "Destination", value: "Destination", sortable: false },
        { text: "Billed", value: "Billed", sortable: true },
        // { text: "Edit dates", value: "actionsEdit", sortable: false },
      ],
      editedItem: {},
      defaultItem: {},
      editedItems: [],
      datePickup: this.dateNow(),
      dateDelivery: this.dateNow(),
      dateFormattedPickup: this.formatDate(this.dateNow()),
      dateFormattedDelivery: this.formatDate(this.dateNow()),
      dateMenu1: false,
      dateMenu2: false,
      minAllowedDatePickup: this.dateNow(),
      minAllowedDateDelivery: this.dateNow(),
      papersChecked: false,
      driverNotes: "",
    };
  },
  computed: {
    ...mapGetters({
      transportOrders: dataGetters.GET_TRANSPORT_ORDERS_DELIVERED,
      transportLogs: dataGetters.GET_LOGS,
      isLoading: dataGetters.IS_LOADING,
      messageFromServer: dataGetters.GET_MESSAGE_FROM_SERVER,
      userName: dataGetters.GET_USERNAME,
      errorBasic: dataGetters.GET_ERROR_BASIC,
    }),
    noData() {
      if (this.transportOrders.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  watch: {
    transportOrders() {
      this.transportOrders.forEach((entry) => {
        let formatted = entry.DeliveredTime.includes(".");
        if (!formatted) {
          entry.DeliveredTime = this.convertDate(entry.DeliveredTime);
          entry.ConfirmedDeliveryTime = this.convertDate(
            entry.ConfirmedDeliveryTime
          );
        }
      });
    },
    datePickup() {
      this.dateFormattedPickup = this.formatDate(this.datePickup);
      this.minAllowedDateDelivery = this.datePickup;
    },
    dateDelivery() {
      this.dateFormattedDelivery = this.formatDate(this.dateDelivery);
    },
  },
  methods: {
    ...mapActions({
      // getTransportOrdersAction: dataActions.GET_TRANSPORT_ORDERS,
      getLogs: dataActions.GET_LOGS,
      updateTransportOrders: dataActions.UPDATE_TRANSPORT_ORDERS,
      updateTransportOrdersKMS: dataActions.UPDATE_TRANSPORT_ORDERS_KMS,
    }),
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ConfirmPickup(item) {
      this.single = true;
      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    ConfirmPickupSelected() {
      this.single = false;
      if (this.selected.length > 1) {
        this.dialog = true;
      } else {
        this.snackType = "info";
        this.noSelection = true;
      }
    },
    closeSnackbar() {
      this.noSelection = false;
    },
    closeEditDialog() {
      this.editSingleDialog = false;
    },
    closeOrderHistory() {
      this.historyDialog = false;
    },

    async ShowHistory(item) {
      let payload = {
        service: "GetLogs",
        serviceType: "GET",
        rowKey: item.Id,
        updater: this.userName,
      };
      await this.getLogs(payload);
      this.history = {
        id: item.Id,
        regno: item.RegNo,
        historyArray: this.transportLogs,
      };
      this.historyDialog = true;
    },
    ReturnToPrevious(item) {
      this.editedItem = item;
      this.editedIndex = this.transportOrders.indexOf(item);
      this.confirmMessage =
        "Confirm you want to move this order back to PENDING CONFIRMATION state.";
      this.confirmVisible = true;
    },
    ConfirmReturnToPreviousState() {
      let payload = {
        service: "MoveOrderPreviousState",
        serviceType: "POST",
        orderStatus: 4,
        orderStatusText: "In delivery",
        updater: this.userName,
        rowKey: this.editedItem.Id,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // Canceling to previous state has no support in KMS
      // let payloadKMS = {
      //   regNo: this.editedItem.RegNo,
      //   vinCode: this.editedItem.Vin,
      //   cancel: "delivered",
      // };

      // Send transport notes to KMS
      // this.updateTransportOrdersKMS(payloadKMS);

      // Remove from UI
      this.transportOrders.splice(this.editedIndex, 1);
      this.editedIndex = -1;
      this.confirmVisible = false;
    },
    closeConfirmDialog() {
      this.confirmVisible = false;
    },
    saveBilledInfo(item) {
      let payload = {
        service: "SaveCommon",
        serviceType: "POST",
        billed: item.Billed,
        updater: this.userName,
        rowKey: item.Id,
      };
      this.updateTransportOrders(payload);

      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    cancelDates() {
      this.dates = [];
      this.dateMenu = false;
    },
  },
};
</script>
<style scoped>
.oneLine {
  text-align: left;
}
</style>
