import parse from "date-fns/parse";
export const helpersMixin = {
  methods: {
    convertDate(date) {
      //Conver dates more humane form
      if (date) {
        var d = new Date(date);
        var options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        };
        var formattedDate = d.toLocaleDateString("fi-FI", options);
        return formattedDate;
      } else {
        return null;
      }
    },

    convertDateTime(date) {
      //Conver dates more humane form
      if (date) {
        var d = new Date(date);
        var options = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        var formattedDate = d.toLocaleDateString("fi-FI", options);
        return formattedDate;
      } else {
        return null;
      }
    },

    handleWeirdDate(value) {
      let d1 = value.split(" klo ");
      const [day1, month1, year1] = d1[0].split(".");

      let timeInParts1 = d1[1].split(".");
      const time1 = `${timeInParts1[0].padStart(
        2,
        "0"
      )}:${timeInParts1[1].padStart(2, "0")}`;

      let date1 =
        `${year1}-${month1.padStart(2, "0")}-${day1.padStart(2, "0")}` +
        "T" +
        time1;
      return new Date(date1);
    },

    parseISO8601(value) {
      return new Date(value);
    },

    parseFinnishDatetime(value) {
      return parse(value, "d.M.yyyy H.mm", new Date());
    },

    dateConverter(value) {
      if (value.includes(" klo ")) {
        return this.handleWeirdDate;
      } else if (value.includes(" ")) {
        return this.parseFinnishDatetime;
      } else {
        return this.parseISO8601;
      }
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "CreateTimestamp") {
          let date1 = this.dateConverter(a[index])(a[index]);
          let date2 = this.dateConverter(b[index])(b[index]);

          if (!isDesc[0]) {
            // return new Date(a[index]) - new Date(b[index]);
            return date1 - date2;
          } else {
            return date2 - date1;
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (typeof a[index] === "number") {
              if (!isDesc[0]) {
                return a[index] - b[index];
              } else {
                return b[index] - a[index];
              }
            } else {
              if (!isDesc[0]) {
                return a[index]
                  .toLowerCase()
                  .localeCompare(b[index].toLowerCase());
              } else {
                return b[index]
                  .toLowerCase()
                  .localeCompare(a[index].toLowerCase());
              }
            }
          }
        }
      });
      return items;
    },

    filterData(data, query) {
      const filteredData = data.filter((item) => {
        for (let key in query) {
          if (item[key] === undefined || !query[key].includes(item[key])) {
            return false;
          }
        }
        return true;
      });
      return filteredData;
    },

    dateNow() {
      let date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      return date;
    },

    dateTimeNow() {
      var dt = new Date().toISOString().slice(0, 19) + "Z";
      return dt;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      let formatedDate = new Array();

      if (date.includes("klo")) {
        formatedDate = date.split(" klo ");
      } else {
        formatedDate[0] = date;
      }

      const [day, month, year] = formatedDate[0].split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    convertTZ(date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "fi-FI",
          { timeZone: tzString }
        )
      );
    },
  },
};
