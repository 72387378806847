<template>
  <v-dialog v-model="dialog" max-width="1300px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >History for order {{ history.id }} ( Regno:
          {{ history.regno }})</span
        >
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Timestamp</th>
                <th class="text-left">Action</th>
                <th class="text-left">Estimated pickup</th>
                <th class="text-left">Estimated delivery</th>
                <th class="text-left">Confirmed pickup</th>
                <th class="text-left">Confirmed delivery</th>
                <th class="text-left">Delivered</th>
                <th class="text-left">Status</th>
                <th class="text-left">Created by</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in history.historyArray" :key="item.Id">
                <td>{{ convertDateTime(item.timestamp) }}</td>
                <td>{{ item.action }}</td>
                <td>{{ convertDate(item.estimated_pickup_time) }}</td>
                <td>{{ convertDate(item.estimated_delivery_time) }}</td>
                <td>{{ convertDate(item.confirmed_pickup_time) }}</td>
                <td>{{ convertDate(item.confirmed_delivery_time) }}</td>
                <td>{{ convertDate(item.delivered) }}</td>
                <td>{{ item.order_status }}</td>
                <td>{{ item.created_by }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helpersMixin } from "@/mixins/mixins.js";

export default {
  mixins: [helpersMixin],
  name: "HistoryDialog",
  props: {
    history: {
      type: Object,
      required: true,
      default() {
        return { message: "Testing" };
      },
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    close() {
      this.$emit("onHistoryClose");
      this.dialog = false;
    },
  },
};
</script>
