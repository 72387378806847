<template>
  <v-container>
    <span class="permission">{{ permissions }}</span>
    <v-row class="text-center">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="green"
        slider-color="green"
      >
        <v-tab>Pending confirmation</v-tab>
        <v-tab>Waiting for pickup</v-tab>
        <v-tab>In delivery</v-tab>
        <v-tab>Delivered</v-tab>
        <v-tab>Statistics</v-tab>
        <v-tab-item>
          <Pending />
        </v-tab-item>
        <v-tab-item>
          <WaitingPickUp />
        </v-tab-item>
        <v-tab-item>
          <PickedUp />
        </v-tab-item>
        <v-tab-item>
          <Delivered />
        </v-tab-item>
        <v-tab-item>
          <Statistics />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import PickedUp from "@/components/transportTabs/pickedUpTable.vue";
import WaitingPickUp from "@/components/transportTabs/waitingPickUpTable.vue";
import Pending from "@/components/transportTabs/pendingTable.vue";
import Delivered from "@/components/transportTabs/deliveredTable.vue";
import Statistics from "@/components/transportTabs/statistics.vue";
import { dataActions } from "@/store/actions";
import { mapActions, mapGetters } from "vuex";
import { dataGetters } from "@/store/getters";

export default {
  name: "transportMain",
  components: {
    PickedUp,
    WaitingPickUp,
    Pending,
    Delivered,
    Statistics,
  },
  data() {
    return {
      // tab: null,
      tab: 0,
    };
  },
  created() {
    if (this.tab < 4 && this.user.length > 0) {
      this.getTransportOrders(this.tab);
    }
  },
  computed: {
    ...mapGetters({
      user: dataGetters.GET_USERNAME,
      permissions: dataGetters.GET_PERMISSIONS,
    }),
  },
  watch: {
    tab() {
      if (this.tab < 4) {
        this.getTransportOrders(this.tab);
      }
    },
  },
  methods: {
    ...mapActions({
      getPermissions: dataActions.GET_PERMISSIONS,
      getTransportOrdersAction: dataActions.GET_TRANSPORT_ORDERS,
    }),
    async getTransportOrders(tab) {
      const userPayload = {
        user: this.user,
      };
      await this.getPermissions(userPayload);

      const payload = {
        service: 0,
        serviceType: "GET",
        updater: this.user,
        permission: this.permissions,
      };

      if (tab == 0) {
        payload.service = 1; // GetWaitingForConfirmation
      } else if (tab == 1) {
        payload.service = 3; // GetWaitingForPickup
      } else if (tab == 2) {
        payload.service = 4; // GetInDelivery
      } else if (tab == 3) {
        payload.service = 5; // GetDelivered
      }

      this.getTransportOrdersAction(payload);
    },
  },
};
</script>
<style>
.snackBar-margin-top {
  margin-top: 80px;
}

.permission {
  position: fixed; /* or absolute */
  left: 50%;
  margin-top: -130px;
  color: white;
  z-index: 1000;
}
</style>
