import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import AuthService from "../config/msal/index";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuthorization) {
    // Only proceed if authenticated.
    var isAuthorized = false;
    // let authService = new AuthService();
    // var userProfile = authService.getUser(); //authentication.getUserProfile()

    if (isAuthorized) {
      store.commit("initAuthorized", true);
      next();
    } else {
      store.commit("initAuthorized", false);
    }
  } else {
    next();
  }
});

export default router;
