class MapService {
  mapOrderData(data) {
    var result = data.map((data) => ({
      Id: data.id ?? "",
      Brand: data.brand ?? "",
      ConfirmedPickupTime: data.confirmed_pickup_time ?? "",
      ConfirmedDeliveryTime: data.confirmed_delivery_time ?? "",
      CreateBy: data.created_by ?? "",
      CreateTimestamp: data.create_time ?? "",
      DeliveredTime: data.delivered_time ?? "",
      Destination: data.destination ?? "",
      DestinationAddress: data.destination_address ?? "",
      DriverNotes: data.driver_notes ?? "",
      EstimatedPickupTime: data.estimated_pickup_time ?? "",
      EstimatedDeliveryTime: data.estimated_delivery_time ?? "",
      ExternalId: data.vehicle_id ?? "",
      ForeignLicensePlate: data.foreign_regno ?? "",
      Model: data.model ?? "",
      OrderStatusId: data.order_status ?? "",
      PapersChecked: data.papers_checked ?? "",
      PickupAddress: data.pickup_address ?? "",
      PickupInformation: data.pickup_information ?? "",
      PickupOutletName: data.pickup_outlet_name ?? "",
      PickupCountry: data.pickup_country ?? "",
      PlannedPickupTime: data.planned_pickup_time ?? "",
      ReceivedAndInspectedTimestamp: data.received_time ?? "",
      RegNo: data.regno ?? "",
      TransportCompany: data.transport_company ?? "",
      TransportInformation: data.transport_information ?? "",
      TransportNotes: data.transport_notes ?? "",
      Ref: data.ref ?? "",
      TransportStatusText: data.order_status_text ?? "",
      Vin: data.vin ?? "",
      Billed: data.billed ?? "",
    }));

    return result;
  }
}

export default new MapService();
