<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-alert type="info"></v-alert>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="accept">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "confirmModal",
  props: {
    confirmMessage: {
      type: String,
      required: false,
      default: function () {
        return "Are you sure?";
      },
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    close() {
      this.$emit("onConfirmClose");
      this.dialog = false;
    },
    accept() {
      this.$emit("onConfirmAccept");
      this.dialog = false;
    },
  },
};
</script>
