<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Edit dates for orders</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-menu
                ref="dateMenu1"
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datePickup"
                    label="Pickup Date"
                    hint="DD.MM.YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(datePickup)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateParsedPickup"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu1 = false"
                ></v-date-picker>
                <!-- <v-date-picker
                  v-model="dateParsedPickup"
                  :min="minAllowedDatePickup"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu1 = false"
                ></v-date-picker> -->
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-menu
                ref="dateMenu2"
                v-model="dateMenu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateDelivery"
                    label="Delivery Date"
                    hint="DD.MM.YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateDelivery)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateParsedDelivery"
                  :min="minAllowedDateDelivery"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-container class="mt-10">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card class="md-2" elevation="1">
                  <v-card-title class="text-subtitle-1"
                    >Car Details</v-card-title
                  >
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Regno</th>
                            <th class="text-left">Brand</th>
                            <th class="text-left">Model</th>
                            <th class="text-left">Vin</th>
                            <th class="text-left">Orginal regno</th>
                            <th class="text-left">Pickup location</th>
                            <th class="text-left">Delivery location</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in selectedProp" :key="item.RegNo">
                            <td>{{ item.RegNo }}</td>
                            <td>{{ item.Brand }}</td>
                            <td>{{ item.Model }}</td>
                            <td>{{ item.Vin }}</td>
                            <td>*************</td>
                            <td>{{ item.PickupAddress }}</td>
                            <td>{{ item.DestinationAddress }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="saveMulti"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helpersMixin } from "@/mixins/mixins.js";

export default {
  mixins: [helpersMixin],
  name: "editOrderMulti",
  props: {
    selectedProp: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    const datePickup = this.formatDate(this.dateNow());
    const dateDelivery = this.formatDate(this.dateNow());
    return {
      dialog: true,
      datePickup: datePickup,
      dateDelivery: dateDelivery,
      dateParsedPickup: this.parseDate(datePickup),
      dateParsedDelivery: this.parseDate(dateDelivery),
      dateMenu1: false,
      dateMenu2: false,
      minAllowedDatePickup: this.dateNow(),
      minAllowedDateDelivery: this.dateNow(),
    };
  },
  watch: {
    dateParsedPickup() {
      this.datePickup = this.formatDate(this.dateParsedPickup);
      this.dateDelivery = this.formatDate(this.dateParsedDelivery);
      this.minAllowedDateDelivery = this.parseDate(this.datePickup);
    },
    dateParsedDelivery() {
      this.dateDelivery = this.formatDate(this.dateParsedDelivery);
    },
  },
  methods: {
    close() {
      this.$emit("onEditClose");
      this.dialog = false;
    },
    saveMulti() {
      let payload = {
        dateParsedPickup: this.dateParsedPickup,
        dateParsedDelivery: this.dateParsedDelivery,
      };
      this.$emit("onEditSave", payload);
      this.dialog = false;
    },
  },
};
</script>
