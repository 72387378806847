const dataActions = {
  GET_TRANSPORT_ORDERS: "getTransportOrders",
  GET_PERMISSIONS: "getPermissions",
  GET_LOGS: "getLogs",
  // SET_ESTIMATED_PICKUP_DELIVERY_TIME: "updateEstimatedPickupDeliveryTime",
  // SET_ESTIMATED_PICKUP_DELIVERY_TIMES: "updateEstimatedPickupDeliveryTimes",
  // SET_CONFIRMED_PICKUP_DELIVERY_TIME: "updateConfirmedPickupDeliveryTime",
  // SET_CONFIRMED_PICKUP_DELIVERY_TIMES: "updateConfirmedPickupDeliveryTimes",
  UPDATE_TRANSPORT_ORDERS: "updateTransportOrders",
  UPDATE_TRANSPORT_ORDERS_KMS: "updateTransportOrdersKMS",
};

export { dataActions };
