<template>
  <v-card>
    <v-card-title>
      <!-- <v-btn color="primary" dark class="mb-2" @click="ConfirmPickupSelected()">
        Confirm all selected for delivery
      </v-btn> -->
      <!-- <v-spacer></v-spacer> -->
      <h2>Waiting for pickup</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="editMultiple()">
        Edit all selected
      </v-btn>
    </v-card-title>
    <v-data-table
      dense
      v-model="selected"
      :single-select="false"
      :headers="headers"
      :items="transportOrders"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="isLoading"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :sort-by="'EstimatedPickupTime'"
      :sort-desc="true"
      :custom-sort="customSort"
      item-key="Id"
      show-expand
      show-select
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="green lighten-5">
          <v-container>
            <v-row :colspan="headers.length" no-gutters>
              <v-col cols="4">
                <v-card height="220px">
                  <!-- <v-card-title class="subtitle-2"> Order info </v-card-title> -->
                  <v-card-text>
                    <div class="oneLine"><b>Brand:</b> {{ item.Brand }}</div>
                    <div class="oneLine"><b>Model:</b> {{ item.Model }}</div>
                    <!-- <div class="oneLine"><b>VIN:</b> {{ item.Vin }}</div> -->
                    <div class="oneLine"><b>Regno:</b> {{ item.RegNo }}</div>
                    <div class="oneLine">
                      <b>Orig. regno:</b> {{ item.ForeignLicensePlate }}
                    </div>
                    <div class="oneLine">
                      <b>Pickup outlet:</b> {{ item.PickupOutletName }}
                    </div>
                    <div class="oneLine">
                      <b>Delivery address:</b> {{ item.DestinationAddress }}
                    </div>
                    <div class="oneLine">
                      <b>Created by:</b> {{ item.CreateBy }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      elevation="2"
                      x-small
                      @click="ShowHistory(item)"
                      >Show order history</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      elevation="2"
                      x-small
                      @click="printPdf(item)"
                      >Print waybill</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red"
                      elevation="2"
                      x-small
                      @click="ReturnToPrevious(item)"
                      >Cancel to pending</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="pickupInformation"
                        label="Kamux pickup information"
                        v-model="item.PickupInformation"
                        disabled
                      ></v-textarea>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="transportNotes"
                        label="Transport notes"
                        v-model="item.TransportNotes"
                        height="120"
                      ></v-textarea>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          elevation="2"
                          x-small
                          @click="SaveTransportNotes(item)"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          elevation="3"
          x-small
          @click="ConfirmPickup(item)"
          >Confirm</v-btn
        >
      </template>
      <template v-slot:[`item.actionsEdit`]="{ item }">
        <v-btn color="primary" elevation="3" x-small @click="editSingle(item)"
          >Edit</v-btn
        >
      </template>
      <template v-slot:[`item.Ref`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.Ref"
          large
          persistent
          @save="saveRef(props.item)"
        >
          <div>{{ props.item.Ref }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update REF</div>
            <v-text-field
              v-model="props.item.Ref"
              :rules="[maxChars]"
              label="Edit"
              single-line
              counter
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card v-if="single">
        <v-card-title>
          <span class="text-h5"
            >Confirm vehicle in delivery ({{ editedItem.RegNo }})</span
          >
          <span class="text-subtitle-1">
            Please confirm that the cars has been picked up from the pickup
            location and transporting has begun.
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="12">
                <span>
                  1. Please check the vehicle before loading. If you see
                  anything to note add it to the text field below
                </span>
                <v-textarea
                  v-model="driverNotes"
                  solo
                  name="input-7-4"
                  label="Notes"
                  class="mt-3"
                ></v-textarea>
              </v-col>
              <v-col cols="12" lg="12">
                <span> 2. Are the cars papers inside the car? </span>
                <v-checkbox
                  v-model="papersChecked"
                  label="Yes, papers are in the car"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" lg="12">
                <span>
                  3. Car loaded on to the truck & transport started?
                </span>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-container class="mt-0">
              <v-row>
                <v-col cols="12" lg="12">
                  <v-card elevation="0">
                    <v-card-title class="text-subtitle-1"
                      >Car Details</v-card-title
                    >
                    <v-card-text>
                      <b>Regno:</b> {{ editedItem.RegNo }}<br />
                      <b>Brand:</b> {{ editedItem.Brand }}<br />
                      <b>Model:</b> {{ editedItem.Model }}<br />
                      <b>Vin:</b> {{ editedItem.Vin }}<br />
                      <b>Orginal regno:</b> *************<br />
                      <b>Pick up location:</b>
                      {{ editedItem.PickupAddress }}<br />
                      <b>Delivery location:</b>
                      {{ editedItem.DestinationAddress }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="saveSingle">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <infoSnack
      v-if="noSelection"
      :snackMessage="snackMessage"
      :snackType="snackType"
      @onSnackClose="closeSnackbar"
    />
    <editOrderSingle
      v-if="editSingleDialog"
      :editedItem="editedItem"
      page="waiting"
      @onEditClose="closeEditDialog"
      @onEditSave="saveNewDates"
    />
    <editOrderMulti
      v-if="editMultiDialog"
      :selectedProp="selected"
      @onEditClose="closeEditDialogMulti"
      @onEditSave="saveNewDatesMulti"
    />
    <orderHistory
      v-if="historyDialog"
      :history="history"
      @onHistoryClose="closeOrderHistory"
    />
    <confirm
      v-if="confirmVisible"
      :confirmMessage="confirmMessage"
      @onConfirmClose="closeConfirmDialog"
      @onConfirmAccept="ConfirmReturnToPreviousState"
    />
  </v-card>
</template>
<script>
import { dataActions } from "@/store/actions";
import { dataGetters } from "@/store/getters";
import { mapActions, mapGetters } from "vuex";
import { helpersMixin } from "@/mixins/mixins.js";
import infoSnack from "@/components/snackbars/info-snack.vue";
import editOrderSingle from "@/components/dialogs/edit-order-single.vue";
import editOrderMulti from "@/components/dialogs/edit-order-multi.vue";
import orderHistory from "@/components/dialogs/order-history.vue";
import confirm from "@/components/dialogs/confirm.vue";
import pdfService from "@/service/pdf-service.js";

export default {
  mixins: [helpersMixin],
  components: {
    infoSnack,
    editOrderSingle,
    editOrderMulti,
    orderHistory,
    confirm,
  },
  name: "WaitingPickUp",
  data() {
    return {
      // service: {
      //   service: "GetWaitingForPickup",
      // },
      maxChars: (v) => !v || v.length <= 15 || "Input too long!",
      confirmVisible: false,
      confirmMessage: "",
      historyDialog: false,
      history: {},
      editSingleDialog: false,
      editMultiDialog: false,
      expanded: [],
      single: true,
      selected: [],
      noSelection: false,
      snackMessage: "No transports selected!",
      snackType: "info",
      singleExpand: true,
      search: "",
      dialog: false,
      dialogEditDates: false,
      editedIndex: -1,
      headers: [
        { text: "Confirm in delivery", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
        { text: "id", value: "Id" },
        // {
        //   text: "Regno",
        //   // align: "start",
        //   value: "RegNo",
        //   sortable: true,
        // },
        { text: "VIN", value: "Vin", sortable: true },
        {
          text: "Estimated pick up",
          value: "EstimatedPickupTime",
          sortable: true,
        },
        {
          text: "Estimated delivery",
          value: "EstimatedDeliveryTime",
          sortable: true,
        },
        { text: "Pickup country", value: "PickupCountry", sortable: false },
        { text: "Pickup location", value: "PickupAddress", sortable: false },
        { text: "Destination", value: "Destination", sortable: false },
        { text: "REF (edit)", value: "Ref", sortable: true },
        { text: "Edit", value: "actionsEdit", sortable: false },
      ],
      editedItem: {},
      defaultItem: {},
      editedItems: [],
      datePickup: this.dateNow(),
      dateDelivery: this.dateNow(),
      dateFormattedPickup: this.formatDate(this.dateNow()),
      dateFormattedDelivery: this.formatDate(this.dateNow()),
      dateMenu1: false,
      dateMenu2: false,
      minAllowedDatePickup: this.dateNow(),
      minAllowedDateDelivery: this.dateNow(),
      papersChecked: false,
      driverNotes: "",
    };
  },
  computed: {
    ...mapGetters({
      transportOrders: dataGetters.GET_TRANSPORT_ORDERS_WAITING_FOR_PICKUP,
      transportLogs: dataGetters.GET_LOGS,
      isLoading: dataGetters.IS_LOADING,
      messageFromServer: dataGetters.GET_MESSAGE_FROM_SERVER,
      userName: dataGetters.GET_USERNAME,
      errorBasic: dataGetters.GET_ERROR_BASIC,
    }),
    noData() {
      if (this.transportOrders.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    transportOrders() {
      this.transportOrders.forEach((entry) => {
        let formatted = entry.EstimatedPickupTime.includes(".");
        if (!formatted) {
          entry.EstimatedPickupTime = this.convertDate(
            entry.EstimatedPickupTime
          );
          entry.EstimatedDeliveryTime = this.convertDate(
            entry.EstimatedDeliveryTime
          );
        }
      });
    },
    datePickup() {
      this.dateFormattedPickup = this.formatDate(this.datePickup);
      this.minAllowedDateDelivery = this.datePickup;
    },
    dateDelivery() {
      this.dateFormattedDelivery = this.formatDate(this.dateDelivery);
    },
  },
  methods: {
    ...mapActions({
      // getTransportOrdersAction: dataActions.GET_TRANSPORT_ORDERS,
      getLogs: dataActions.GET_LOGS,
      updateTransportOrders: dataActions.UPDATE_TRANSPORT_ORDERS,
      updateTransportOrdersKMS: dataActions.UPDATE_TRANSPORT_ORDERS_KMS,
    }),
    saveSingle() {
      let payload = {
        service: "ConfirmedPickup",
        serviceType: "POST",
        driverNotes: this.driverNotes,
        papersChecked: this.papersChecked,
        confirmedPickUpTime: this.dateTimeNow(),
        confirmedDeliveryTime: this.parseDate(
          this.editedItem.EstimatedDeliveryTime
        ),
        orderStatus: 4,
        orderStatusText: "In delivery",
        updater: this.userName,
        rowKey: this.editedItem.Id,
      };

      let payloadKMS = {
        regNo: this.editedItem.RegNo,
        vinCode: this.editedItem.Vin,
        pickedUp: this.dateTimeNow(),
        moreInfo: this.driverNotes,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);
      // Call KMS backend api
      this.updateTransportOrdersKMS(payloadKMS);

      // Remove from UI
      this.transportOrders.splice(this.editedIndex, 1);
      this.editedIndex = -1;
      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ConfirmPickup(item) {
      this.single = true;
      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    ConfirmPickupSelected() {
      this.single = false;
      if (this.selected.length > 1) {
        this.dialog = true;
      } else {
        this.snackType = "info";
        this.noSelection = true;
      }
    },
    closeSnackbar() {
      this.noSelection = false;
    },
    closeEditDialog() {
      this.editSingleDialog = false;
    },
    closeEditDialogMulti() {
      this.editMultiDialog = false;
    },
    saveNewDates(dates) {
      let pickupTime = "";
      let deliveryTime = "";

      if (dates.dateParsedPickup) {
        pickupTime = dates.dateParsedPickup;
      } else {
        pickupTime = this.parseDate(this.editedItem.EstimatedPickupTime);
      }

      if (dates.dateParsedDelivery) {
        deliveryTime = dates.dateParsedDelivery;
      } else {
        deliveryTime = this.parseDate(this.editedItem.EstimatedDeliveryTime);
      }

      let payload = {
        service: "SaveNewDatesEstimated",
        serviceType: "POST",
        estimatedPickupTime: pickupTime,
        estimatedDeliveryTime: deliveryTime,
        updater: this.userName,
        rowKey: this.editedItem.Id,
      };

      let payloadKMS = {
        regNo: this.editedItem.RegNo,
        vinCode: this.editedItem.Vin,
        estimatedPickupDate: pickupTime,
        estimatedArriveDate: deliveryTime,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // Call KMS backend api
      this.updateTransportOrdersKMS(payloadKMS);

      this.editedItem.EstimatedPickupTime = this.formatDate(pickupTime);
      this.editedItem.EstimatedDeliveryTime = this.formatDate(deliveryTime);

      if (this.editedIndex > -1) {
        Object.assign(this.transportOrders[this.editedIndex], this.editedItem);
      } else {
        this.transportOrders.push(this.editedItem);
      }

      this.editSingleDialog = false;
    },
    saveNewDatesMulti(dates) {
      let index = 0;
      let dataArray = new Array();
      this.selected.forEach((item) => {
        let pickupTime = "";
        let deliveryTime = "";

        if (dates.dateParsedPickup) {
          pickupTime = dates.dateParsedPickup;
        } else {
          pickupTime = this.parseDate(item.EstimatedPickupTime);
        }

        if (dates.dateParsedDelivery) {
          deliveryTime = dates.dateParsedDelivery;
        } else {
          deliveryTime = this.parseDate(item.EstimatedDeliveryTime);
        }

        dataArray.push({
          estimatedPickupTime: pickupTime,
          estimatedDeliveryTime: deliveryTime,
          updater: this.userName,
          rowKey: item.Id,
        });

        this.selected[index].EstimatedPickupTime = this.formatDate(pickupTime);
        // eslint-disable-next-line prettier/prettier
        this.selected[index].EstimatedDeliveryTime = this.formatDate(deliveryTime);
        index++;

        // Call KMS backend api
        let payloadKMS = {
          regNo: item.RegNo,
          vinCode: item.Vin,
          estimatedPickupDate: pickupTime,
          estimatedArriveDate: deliveryTime,
        };

        this.updateTransportOrdersKMS(payloadKMS);
      });

      let payload = {
        service: "SaveNewDatesEstimated",
        serviceType: "POST",
        confirmDataArray: dataArray,
        updater: this.userName,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      this.editedIndex = -1;
      this.selected = [];
      this.editMultiDialog = false;
    },
    editSingle(item) {
      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editSingleDialog = true;
    },
    editMultiple() {
      if (this.selected.length > 1) {
        this.editMultiDialog = true;
      } else {
        this.snackType = "info";
        this.noSelection = true;
      }
    },
    closeOrderHistory() {
      this.historyDialog = false;
    },
    async ShowHistory(item) {
      let payload = {
        service: "GetLogs",
        serviceType: "GET",
        rowKey: item.Id,
        updater: this.userName,
      };
      await this.getLogs(payload);
      this.history = {
        id: item.Id,
        regno: item.RegNo,
        historyArray: this.transportLogs,
      };
      this.historyDialog = true;
    },
    ReturnToPrevious(item) {
      this.editedItem = item;
      this.editedIndex = this.transportOrders.indexOf(item);
      this.confirmMessage =
        "Confirm you want to move this order back to PENDING CONFIRMATION state.";
      this.confirmVisible = true;
    },
    ConfirmReturnToPreviousState() {
      let payload = {
        service: "MoveOrderPreviousState",
        serviceType: "POST",
        orderStatus: 1,
        orderStatusText: "Pending confirmation",
        updater: this.userName,
        rowKey: this.editedItem.Id,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // Canceling to previous state has no support in KMS
      // let payloadKMS = {
      //   regNo: this.editedItem.RegNo,
      //   vinCode: this.editedItem.Vin,
      //   cancel: "estimation",
      // };

      // Send transport notes to KMS
      // this.updateTransportOrdersKMS(payloadKMS);

      // Remove from UI
      this.transportOrders.splice(this.editedIndex, 1);
      this.editedIndex = -1;
      this.confirmVisible = false;
    },
    closeConfirmDialog() {
      this.confirmVisible = false;
    },
    saveRef(item) {
      let payload = {
        service: "SaveCommon",
        serviceType: "POST",
        ref: item.Ref,
        updater: this.userName,
        rowKey: item.Id,
      };
      this.updateTransportOrders(payload);

      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    SaveTransportNotes(item) {
      let payload = {
        service: "SaveCommon",
        serviceType: "POST",
        transportNotes: item.TransportNotes,
        updater: this.userName,
        rowKey: item.Id,
      };
      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // No support in KMS
      // let payloadKMS = {
      //   regNo: item.RegNo,
      //   vinCode: item.Vin,
      //   transportNotes: item.TransportNotes,
      // };

      // Send transport notes to KMS
      // this.updateTransportOrdersKMS(payloadKMS);
    },

    printPdf(item) {
      pdfService.printWaybillPdf(item);
    },
  },
};
</script>
<style scoped>
.oneLine {
  text-align: left;
}
</style>
