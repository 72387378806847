import * as pdfPrinter from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

class PdfService {
  // rahtikirja
  printWaybillPdf(data) {
    var docDefinition = {
      content: [
        // margin: [left, top, right, bottom]
        {
          text: "KAMUX INTERNATIONAL CONSIGMENT NOTE",
          style: "header",
          margin: [0, 0, 0, 20],
        },
        {
          table: {
            widths: ["*", "*"],
            headerRows: 1,
            body: [
              [
                {
                  text: "RECEIVER:\n\n" + "Kamux " + data.Destination + "\n ",
                  style: "cellFormat",
                  alignment: "left",
                },
                {
                  text: "CARRIER\n\n" + data.TransportCompany + "\n ",
                  style: "cellFormat",
                  alignment: "left",
                },
              ],
              [
                {
                  text:
                    "COLLECT ADDRESS:\n\n" +
                    "Kamux " +
                    data.PickupOutletName +
                    "\n" +
                    data.PickupAddress +
                    "\n ",
                  style: "cellFormat",
                  alignment: "left",
                },
                {
                  text:
                    "DESTINATION ADDRESS\n\n" +
                    "Kamux " +
                    data.Destination +
                    "\n " +
                    data.DestinationAddress,
                  style: "cellFormat",
                  alignment: "left",
                },
              ],
              // [
              //   {
              //     text: "OTHER:\n\n" + data.PickupInformation + "\n ",
              //     style: "cellFormat",
              //     colSpan: 2,
              //     alignment: "left",
              //   },
              // ],
              [
                {
                  text:
                    "ITEMS:\n\n" +
                    "Vehicle: " +
                    data.RegNo +
                    ", " +
                    data.Vin +
                    ", " +
                    data.Brand +
                    " " +
                    data.Model +
                    "\n ",
                  style: "cellFormat",
                  colSpan: 2,
                  alignment: "left",
                },
              ],
              [
                {
                  text: "ESTABLISHED IN:\n\n" + " \n\n" + " ",
                  style: "cellFormat",
                  alignment: "left",
                },
                {
                  text: "ON DATE:\n\n" + " \n\n" + " ",
                  style: "cellFormat",
                  alignment: "left",
                },
              ],
              [
                {
                  text:
                    "SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
                    "\n\n\n\n\n\n\n\n ",
                  style: "cellFormat",
                  alignment: "left",
                },
                {
                  text:
                    "SIGNATURE AND STAMP OF THE CARRIER:\n\n" +
                    "\n\n\n\n\n\n\n\n ",
                  style: "cellFormat",
                  alignment: "left",
                },
              ],
              [
                {
                  text:
                    "ARRAIVING TO PLACE OF DELIVERY, SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
                    "\n\n\n\n\n\n\n\n ",
                  style: "cellFormat",
                  colSpan: 2,
                  alignment: "left",
                },
              ],
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 15,
          bold: true,
        },
        cellFormat: {
          fontSize: 8,
          bold: false,
        },
      },
    };

    pdfPrinter.vfs = pdfFonts.pdfMake.vfs;
    var pdf = pdfPrinter.createPdf(docDefinition);
    // pdf.download("optionalName.pdf");
    pdf.print();
  }

  printWaybillPdfMutipleItems(data) {
    let pdfContentArray = [];
    let itemArray = [];
    let usedIndex = [];
    let round = 0;

    for (let a = 0; a < data.length; a++) {
      let addressConcat1 = data[a].PickupAddress + data[a].DestinationAddress;

      let itemObject = new Object();
      itemObject.regNo = data[a].RegNo;
      itemObject.vin = data[a].Vin;
      itemObject.brand = data[a].Brand;
      itemObject.model = data[a].Model;

      itemArray.push(itemObject);

      for (let b = 0; b < data.length; b++) {
        if (b > a) {
          let addressConcat2 =
            data[b].PickupAddress + data[b].DestinationAddress;

          if (addressConcat1 == addressConcat2) {
            usedIndex.push(b);
            let itemObject = new Object();
            itemObject.regNo = data[b].RegNo;
            itemObject.vin = data[b].Vin;
            itemObject.brand = data[b].Brand;
            itemObject.model = data[b].Model;

            itemArray.push(itemObject);
          }
        }
      }

      if (!usedIndex.includes(a)) {
        let pageBrake = true;
        if (round == 0) {
          pageBrake = false;
        }
        let content = this.fillContent(data[a], itemArray, pageBrake);
        pdfContentArray = pdfContentArray.concat(content);
        round++;
      }
      itemArray = [];
    }

    let docDefinition = {
      content: pdfContentArray,
      styles: {
        header: {
          fontSize: 15,
          bold: true,
        },
        cellFormat: {
          fontSize: 8,
          bold: false,
        },
      },
    };
    // console.log(JSON.stringify(docDefinition));

    pdfPrinter.vfs = pdfFonts.pdfMake.vfs;
    var pdf = pdfPrinter.createPdf(docDefinition);
    // pdf.download("optionalName.pdf");
    pdf.print();
  }

  fillContent(orderData, itemArray, pageBrake) {
    let itemString;
    let round = 0;
    itemArray.forEach((element) => {
      if (round == 0) {
        itemString =
          "Vehicle: " +
          element.regNo +
          ", " +
          element.vin +
          ", " +
          element.brand +
          " " +
          element.model +
          "\n ";
      } else {
        itemString +=
          "\n\nVehicle: " +
          element.regNo +
          ", " +
          element.vin +
          ", " +
          element.brand +
          " " +
          element.model +
          "\n ";
      }
      round++;
    });

    let headerObject = {};
    if (pageBrake) {
      headerObject = {
        pageBreak: "before",
        text: "KAMUX INTERNATIONAL CONSIGMENT NOTE",
        style: "header",
        margin: [0, 0, 0, 20],
      };
    } else {
      headerObject = {
        text: "KAMUX INTERNATIONAL CONSIGMENT NOTE",
        style: "header",
        margin: [0, 0, 0, 20],
      };
    }

    let content = [
      headerObject,
      {
        table: {
          widths: ["*", "*"],
          headerRows: 1,
          body: [
            [
              {
                text:
                  "RECEIVER:\n\n" + "Kamux " + orderData.Destination + "\n ",
                style: "cellFormat",
                alignment: "left",
              },
              {
                text: "CARRIER\n\n" + orderData.TransportCompany + "\n ",
                style: "cellFormat",
                alignment: "left",
              },
            ],
            [
              {
                text:
                  "COLLECT ADDRESS:\n\n" +
                  "Kamux " +
                  orderData.PickupOutletName +
                  "\n" +
                  orderData.PickupAddress +
                  "\n ",
                style: "cellFormat",
                alignment: "left",
              },
              {
                text:
                  "DESTINATION ADDRESS\n\n" +
                  "Kamux " +
                  orderData.Destination +
                  "\n " +
                  orderData.DestinationAddress,
                style: "cellFormat",
                alignment: "left",
              },
            ],
            [
              {
                text: "ITEMS:\n\n" + itemString,
                style: "cellFormat",
                colSpan: 2,
                alignment: "left",
              },
            ],
            [
              {
                text: "ESTABLISHED IN:\n\n" + " \n\n" + " ",
                style: "cellFormat",
                alignment: "left",
              },
              {
                text: "ON DATE:\n\n" + " \n\n" + " ",
                style: "cellFormat",
                alignment: "left",
              },
            ],
            [
              {
                text:
                  "SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
                  "\n\n\n\n\n\n\n\n ",
                style: "cellFormat",
                alignment: "left",
              },
              {
                text:
                  "SIGNATURE AND STAMP OF THE CARRIER:\n\n" +
                  "\n\n\n\n\n\n\n\n ",
                style: "cellFormat",
                alignment: "left",
              },
            ],
            [
              {
                text:
                  "ARRAIVING TO PLACE OF DELIVERY, SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
                  "\n\n\n\n\n\n\n\n ",
                style: "cellFormat",
                colSpan: 2,
                alignment: "left",
              },
            ],
          ],
        },
      },
    ];

    return content;
  }

  // fillPdf(orderData, itemArray) {
  //   let itemString;
  //   let round = 0;
  //   itemArray.forEach((element) => {
  //     if (round == 0) {
  //       itemString =
  //         "Vehicle: " +
  //         element.regNo +
  //         ", " +
  //         element.vin +
  //         ", " +
  //         element.brand +
  //         " " +
  //         element.model +
  //         "\n ";
  //     } else {
  //       itemString +=
  //         "\n\nVehicle: " +
  //         element.regNo +
  //         ", " +
  //         element.vin +
  //         ", " +
  //         element.brand +
  //         " " +
  //         element.model +
  //         "\n ";
  //     }
  //     round++;
  //   });

  //   let docDefinition = {
  //     content: [
  //       // margin: [left, top, right, bottom]
  //       {
  //         text: "KAMUX INTERNATIONAL CONSIGMENT NOTE",
  //         style: "header",
  //         margin: [0, 0, 0, 20],
  //       },
  //       {
  //         table: {
  //           pageBreak: "after",
  //           widths: ["*", "*"],
  //           headerRows: 1,
  //           body: [
  //             [
  //               {
  //                 text:
  //                   "RECEIVER:\n\n" + "Kamux " + orderData.Destination + "\n ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //               {
  //                 text: "CARRIER\n\n" + orderData.TransportCompany + "\n ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //             ],
  //             [
  //               {
  //                 text:
  //                   "COLLECT ADDRESS:\n\n" +
  //                   "Kamux " +
  //                   orderData.PickupOutletName +
  //                   "\n" +
  //                   orderData.PickupAddress +
  //                   "\n ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //               {
  //                 text:
  //                   "DESTINATION ADDRESS\n\n" +
  //                   "Kamux " +
  //                   orderData.Destination +
  //                   "\n " +
  //                   orderData.DestinationAddress,
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //             ],
  //             [
  //               {
  //                 text: "ITEMS:\n\n" + itemString,
  //                 style: "cellFormat",
  //                 colSpan: 2,
  //                 alignment: "left",
  //               },
  //             ],
  //             [
  //               {
  //                 text: "ESTABLISHED IN:\n\n" + " \n\n" + " ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //               {
  //                 text: "ON DATE:\n\n" + " \n\n" + " ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //             ],
  //             [
  //               {
  //                 text:
  //                   "SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
  //                   "\n\n\n\n\n\n\n\n ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //               {
  //                 text:
  //                   "SIGNATURE AND STAMP OF THE CARRIER:\n\n" +
  //                   "\n\n\n\n\n\n\n\n ",
  //                 style: "cellFormat",
  //                 alignment: "left",
  //               },
  //             ],
  //             [
  //               {
  //                 text:
  //                   "ARRAIVING TO PLACE OF DELIVERY, SIGNATURE AND STAMP OF THE CONSIGNEE:\n\n" +
  //                   "\n\n\n\n\n\n\n\n ",
  //                 style: "cellFormat",
  //                 colSpan: 2,
  //                 alignment: "left",
  //               },
  //             ],
  //           ],
  //         },
  //       },
  //     ],
  //     styles: {
  //       header: {
  //         fontSize: 15,
  //         bold: true,
  //       },
  //       cellFormat: {
  //         fontSize: 8,
  //         bold: false,
  //       },
  //     },
  //   };

  //   return docDefinition;
  // }
}

export default new PdfService();
