<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    if (!this.$AuthService.getIsAuthenticated()) {
      try {
        this.$AuthService.loginRedirect({});
      } catch (err) {
        // handle error
      }
    } else {
      this.$store.commit("initUserName", this.$AuthService.getUserName());
    }
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 12px !important;
}
</style>
