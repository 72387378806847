<template>
  <v-card>
    <v-card-title>
      <h2>Statistics</h2>
    </v-card-title>
    <infoSnack
      v-if="noSelection"
      :snackMessage="snackMessage"
      :snackType="snackType"
      @onSnackClose="closeSnackbar"
    />
  </v-card>
</template>
<script>
import { helpersMixin } from "@/mixins/mixins.js";
import infoSnack from "@/components/snackbars/info-snack.vue";

export default {
  mixins: [helpersMixin],
  components: {
    infoSnack,
  },
  name: "Statistics",
  data() {
    return {
      admin: false,
      noSelection: false,
    };
  },
};
</script>
