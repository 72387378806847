<template>
  <v-card>
    <v-card-title>
      <v-btn color="primary" dark class="mb-2" @click="ConfirmPickupSelected()">
        Confirm all selected
      </v-btn>
      <v-spacer></v-spacer>
      <h2>Pending confimation</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="printPdfMultiple()">
        Print waybill for selected
      </v-btn>
    </v-card-title>
    <v-data-table
      dense
      v-model="selected"
      :single-select="false"
      :headers="headers"
      :items="transportOrders"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :loading="isLoading"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :sort-by="'CreateTimestamp'"
      :sort-desc="true"
      :custom-sort="customSort"
      item-key="Id"
      show-expand
      show-select
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:expanded-item="{ headers, item }" class="green">
        <td :colspan="headers.length" class="green lighten-5">
          <v-container>
            <v-row :colspan="headers.length" no-gutters>
              <v-col cols="4">
                <v-card height="220px">
                  <!-- <v-card-title class="subtitle-2"> Order info </v-card-title> -->
                  <v-card-text>
                    <div class="oneLine"><b>Brand:</b> {{ item.Brand }}</div>
                    <div class="oneLine"><b>Model:</b> {{ item.Model }}</div>
                    <!-- <div class="oneLine"><b>VIN:</b> {{ item.Vin }}</div> -->
                    <div class="oneLine"><b>Regno:</b> {{ item.RegNo }}</div>
                    <div class="oneLine">
                      <b>Orig. regno:</b> {{ item.ForeignLicensePlate }}
                    </div>
                    <div class="oneLine">
                      <b>Pickup outlet:</b> {{ item.PickupOutletName }}
                    </div>
                    <div class="oneLine">
                      <b>Delivery address:</b> {{ item.DestinationAddress }}
                    </div>
                    <div class="oneLine">
                      <b>Created by:</b> {{ item.CreateBy }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      elevation="2"
                      x-small
                      @click="printPdf(item)"
                      >Print waybill</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      elevation="2"
                      x-small
                      @click="ShowHistory(item)"
                      >Show order history</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="pickupInformation"
                        label="Kamux pickup information"
                        v-model="item.PickupInformation"
                        disabled
                      ></v-textarea>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card height="220px">
                  <v-card-text>
                    <div class="oneLine">
                      <v-textarea
                        name="transportNotes"
                        label="Transport notes"
                        v-model="item.TransportNotes"
                        height="120"
                      ></v-textarea>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          elevation="2"
                          x-small
                          @click="SaveTransportNotes(item)"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          elevation="3"
          x-small
          @click="ConfirmPickup(item)"
          >Confirm</v-btn
        >
      </template>
      <template v-slot:[`item.Ref`]="props">
        <v-edit-dialog
          :return-value.sync="props.item.Ref"
          large
          persistent
          @save="saveRef(props.item)"
        >
          <div>{{ props.item.Ref }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update REF</div>
            <v-text-field
              v-model="props.item.Ref"
              :rules="[maxChars]"
              label="Edit"
              single-line
              counter
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card v-if="single">
        <v-card-title>
          <span class="text-h5"
            >Confirm transport order ({{ editedItem.RegNo }})</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="dateMenu1"
                  v-model="dateMenu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedPickup"
                      label="Pickup Date"
                      hint="DD.MM.YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormattedPickup)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datePickup"
                    :min="minAllowedDatePickup"
                    :first-day-of-week="1"
                    locale="fi-FI"
                    no-title
                    @input="dateMenu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="dateMenu2"
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedDelivery"
                      label="Delivery Date"
                      hint="DD.MM.YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormattedDelivery)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDelivery"
                    :min="minAllowedDateDelivery"
                    :first-day-of-week="1"
                    locale="fi-FI"
                    no-title
                    @input="dateMenu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-container class="mt-10">
              <v-row>
                <v-col cols="12" lg="6">
                  <v-card class="mr-1 ml-1" elevation="1">
                    <v-card-title class="text-subtitle-1"
                      >Pickup location details</v-card-title
                    >
                    <v-card-text>
                      {{ editedItem.PickupAddress }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-card class="mr-1 ml-1" elevation="1">
                    <v-card-title class="text-subtitle-1"
                      >Delivery location details</v-card-title
                    >
                    <v-card-text>
                      {{ editedItem.DestinationAddress }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-card class="md-2" elevation="1">
                    <v-card-title class="text-subtitle-1"
                      >Car Details</v-card-title
                    >
                    <v-card-text>
                      <b>Regno:</b> {{ editedItem.RegNo }}<br />
                      <b>Brand:</b> {{ editedItem.Brand }}<br />
                      <b>Model:</b> {{ editedItem.Model }}<br />
                      <b>Vin:</b> {{ editedItem.Vin }}<br />
                      <b>Orginal regno:</b> *************<br />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="saveSingle"> Save </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span class="text-h5">Confirm transport orders</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="dateMenu1"
                  v-model="dateMenu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedPickup"
                      label="Pickup Date"
                      hint="DD.MM.YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormattedPickup)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datePickup"
                    :min="minAllowedDatePickup"
                    :first-day-of-week="1"
                    locale="fi-FI"
                    no-title
                    @input="dateMenu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="dateMenu2"
                  v-model="dateMenu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedDelivery"
                      label="Delivery Date"
                      hint="DD.MM.YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormattedDelivery)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateDelivery"
                    :min="minAllowedDateDelivery"
                    :first-day-of-week="1"
                    locale="fi-FI"
                    no-title
                    @input="dateMenu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-container class="mt-10">
              <v-row>
                <v-col cols="12" lg="12">
                  <v-card class="md-2" elevation="1">
                    <v-card-title class="text-subtitle-1"
                      >Car Details</v-card-title
                    >
                    <v-card-text>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Regno</th>
                              <th class="text-left">Brand</th>
                              <th class="text-left">Model</th>
                              <th class="text-left">Vin</th>
                              <th class="text-left">Orginal regno</th>
                              <th class="text-left">Pickup location</th>
                              <th class="text-left">Delivery location</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in selected" :key="item.RegNo">
                              <td>{{ item.RegNo }}</td>
                              <td>{{ item.Brand }}</td>
                              <td>{{ item.Model }}</td>
                              <td>{{ item.Vin }}</td>
                              <td>*************</td>
                              <td>{{ item.PickupAddress }}</td>
                              <td>{{ item.DestinationAddress }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="saveMultiple"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <infoSnack
      v-if="noSelection"
      :snackMessage="snackMessage"
      :snackType="snackType"
      @onSnackClose="closeSnackbar"
    />
    <orderHistory
      v-if="historyDialog"
      :history="history"
      @onHistoryClose="closeOrderHistory"
    />
  </v-card>
</template>
<script>
import { dataActions } from "@/store/actions";
import { dataGetters } from "@/store/getters";
import { mapActions, mapGetters } from "vuex";
import { helpersMixin } from "@/mixins/mixins.js";
import infoSnack from "@/components/snackbars/info-snack.vue";
import orderHistory from "@/components/dialogs/order-history.vue";
import pdfService from "@/service/pdf-service.js";

export default {
  mixins: [helpersMixin],
  components: {
    infoSnack,
    orderHistory,
  },
  name: "PickUpTable",
  data() {
    return {
      // service: {
      //   service: 1,
      //   serviceType: "GET",
      //   permission: "",
      // },
      maxChars: (v) => !v || v.length <= 15 || "Input too long!",
      historyDialog: false,
      pdfOn: false,
      history: {},
      expanded: [],
      single: true,
      selected: [],
      noSelection: false,
      snackMessage: "No transports selected!",
      snackType: "info",
      singleExpand: true,
      search: "",
      dialog: false,
      editedIndex: -1,
      headers: [
        { text: "Confirm pickup", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
        { text: "id", value: "Id" },
        // {
        //   text: "Regno",
        //   // align: "start",
        //   value: "RegNo",
        //   sortable: true,
        // },
        { text: "VIN", value: "Vin", sortable: true },
        // { text: "Brand", value: "Brand" },
        // { text: "Model", value: "Model" },
        { text: "Created", value: "CreateTimestamp", sortable: true },
        // { text: "Created by", value: "CreateBy", sortable: false },
        {
          text: "Available for pickup",
          value: "PlannedPickupTime",
          sortable: false,
        },
        //{ text: "Pickup outlet", value: "PickupOutletName", sortable: false },
        { text: "Pickup country", value: "PickupCountry", sortable: false },
        { text: "Pickup location", value: "PickupAddress", sortable: false },
        { text: "Destination", value: "Destination", sortable: false },
        { text: "Company", value: "TransportCompany", sortable: false },
        { text: "REF (edit)", value: "Ref", sortable: true },
      ],
      editedItem: {
        estimatedPickupTime: "",
        estimatedDelivery: "",
      },
      defaultItem: {
        estimatedPickupTime: "",
        estimatedDelivery: "",
      },
      editedItems: [],
      datePickup: this.dateNow(),
      dateDelivery: this.dateNow(),
      dateFormattedPickup: this.formatDate(this.dateNow()),
      dateFormattedDelivery: this.formatDate(this.dateNow()),
      dateMenu1: false,
      dateMenu2: false,
      minAllowedDatePickup: this.dateNow(),
      minAllowedDateDelivery: this.dateNow(),
    };
  },
  computed: {
    ...mapGetters({
      transportOrders: dataGetters.GET_TRANSPORT_ORDERS_PENDING,
      transportLogs: dataGetters.GET_LOGS,
      isLoading: dataGetters.IS_LOADING,
      messageFromServer: dataGetters.GET_MESSAGE_FROM_SERVER,
      userName: dataGetters.GET_USERNAME,
      errorBasic: dataGetters.GET_ERROR_BASIC,
      permissions: dataGetters.GET_PERMISSIONS,
    }),
    noData() {
      if (this.transportOrders.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    transportOrders() {
      this.transportOrders.forEach((entry) => {
        let formatted = entry.CreateTimestamp.includes("klo");
        if (!formatted) {
          entry.CreateTimestamp = this.convertDateTime(entry.CreateTimestamp);
          entry.PlannedPickupTime = this.convertDateTime(
            entry.PlannedPickupTime
          );
        }
      });
    },
    datePickup() {
      this.dateFormattedPickup = this.formatDate(this.datePickup);
      this.minAllowedDateDelivery = this.datePickup;
    },
    dateDelivery() {
      this.dateFormattedDelivery = this.formatDate(this.dateDelivery);
    },
  },
  methods: {
    ...mapActions({
      getLogs: dataActions.GET_LOGS,
      updateTransportOrders: dataActions.UPDATE_TRANSPORT_ORDERS,
      updateTransportOrdersKMS: dataActions.UPDATE_TRANSPORT_ORDERS_KMS,
    }),
    saveSingle() {
      let payload = {
        service: "EstimatedPickup",
        serviceType: "POST",
        estimatedPickupTime: this.datePickup,
        estimatedDeliveryTime: this.dateDelivery,
        orderStatus: 3,
        orderStatusText: "Waiting for pick up",
        updater: this.userName,
        rowKey: this.editedItem.Id,
      };

      let payloadKMS = {
        regNo: this.editedItem.RegNo,
        vinCode: this.editedItem.Vin,
        estimatedPickupDate: this.datePickup,
        estimatedArriveDate: this.dateDelivery,
      };
      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // Call KMS backend api
      this.updateTransportOrdersKMS(payloadKMS);

      // Remove from UI
      this.transportOrders.splice(this.editedIndex, 1);
      this.editedIndex = -1;
      this.dialog = false;
    },
    saveMultiple() {
      let dataArray = new Array();
      this.selected.forEach((item) => {
        dataArray.push({
          estimatedPickupTime: this.datePickup,
          estimatedDeliveryTime: this.dateDelivery,
          orderStatus: 3,
          orderStatusText: "Waiting for pick up",
          updater: this.userName,
          rowKey: item.Id,
        });

        // Call KMS backend api
        let payloadKMS = {
          regNo: item.RegNo,
          vinCode: item.Vin,
          estimatedPickupDate: this.datePickup,
          estimatedArriveDate: this.dateDelivery,
        };
        this.updateTransportOrdersKMS(payloadKMS);
      });

      let payload = {
        service: "EstimatedPickup",
        serviceType: "POST",
        confirmDataArray: dataArray,
        updater: this.userName,
      };
      // Call Transport UI backend apis
      this.updateTransportOrders(payload);

      // Remove from UI
      for (var i = 0; i < this.selected.length; i++) {
        const index = this.transportOrders.indexOf(this.selected[i]);
        this.transportOrders.splice(index, 1);
      }
      this.editedIndex = -1;
      this.selected = [];
      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ConfirmPickup(item) {
      this.single = true;
      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    ConfirmPickupSelected() {
      this.single = false;
      if (this.selected.length > 1) {
        this.dialog = true;
      } else {
        this.snackType = "info";
        this.noSelection = true;
      }
    },
    closeSnackbar() {
      this.noSelection = false;
    },
    closeOrderHistory() {
      this.historyDialog = false;
    },
    async ShowHistory(item) {
      let payload = {
        service: "GetLogs",
        serviceType: "GET",
        rowKey: item.Id,
        updater: this.userName,
      };
      await this.getLogs(payload);
      this.history = {
        id: item.Id,
        regno: item.RegNo,
        historyArray: this.transportLogs,
      };
      this.historyDialog = true;
    },
    saveRef(item) {
      let payload = {
        service: "SaveCommon",
        serviceType: "POST",
        ref: item.Ref,
        updater: this.userName,
        rowKey: item.Id,
      };
      this.updateTransportOrders(payload);

      this.editedIndex = this.transportOrders.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    SaveTransportNotes(item) {
      let payload = {
        service: "SaveCommon",
        serviceType: "POST",
        transportNotes: item.TransportNotes,
        updater: this.userName,
        rowKey: item.Id,
      };

      // Call Transport UI backend api
      this.updateTransportOrders(payload);

      // Updating transport notes to KMS is not supported
      // let payloadKMS = {
      //   regNo: item.RegNo,
      //   vinCode: item.Vin,
      //   transportNotes: item.TransportNotes,
      // };

      // Send transport notes to KMS
      // this.updateTransportOrdersKMS(payloadKMS);
    },

    printPdf(item) {
      pdfService.printWaybillPdf(item);
    },

    printPdfMultiple() {
      if (this.selected.length >= 1) {
        pdfService.printWaybillPdfMutipleItems(this.selected);
      } else {
        this.snackType = "info";
        this.noSelection = true;
      }
    },
  },
};
</script>
<style>
.oneLine {
  text-align: left;
}
.rowStyleNew {
  background-color: rgb(177, 255, 177);
}
.rowStyleOld {
  background-color: rgb(182, 182, 255);
}
</style>
