class CallService {
  CallServiceAction(serviceMethod, context, successMessage, errorMessage) {
    return new Promise(function (resolve, reject) {
      context.commit("setIsLoading", true);
      serviceMethod
        .then(function (data) {
          if (successMessage) {
            context.commit("setMessageFromServer", {
              message: successMessage,
              isSuccess: true,
            });
            context.commit("setResultMessage", {
              message: successMessage,
              error: "",
            });
          } else {
            context.commit("setMessageFromServer", {
              message: "Done!",
              isSuccess: true,
            });
          }
          context.commit("setIsLoading", false);
          return resolve(data);
        })
        .catch(function (error) {
          context.commit("setIsLoading", false);
          if (errorMessage) {
            context.commit("setMessageFromServer", {
              message: errorMessage + error,
              isSuccess: false,
            });
            context.commit("setResultMessage", {
              message: errorMessage,
              error: error,
            });
          } else {
            context.commit("setMessageFromServer", {
              message: "Failed! " + error,
              isSuccess: false,
            });
          }
          return reject(error);
        });
    });
  }

  CallServiceGet(serviceMethod, context, successMessage, errorMessage) {
    return new Promise(function (resolve, reject) {
      context.commit("setIsLoading", true);
      serviceMethod
        .then(function (data) {
          if (successMessage) {
            context.commit("setMessageFromServer", {
              message: successMessage,
              isSuccess: true,
            });
          } else {
            context.commit("setMessageFromServer", {
              message: "Done!",
              isSuccess: true,
            });
          }
          context.commit("setIsLoading", false);
          return resolve(data);
        })
        .catch(function (error) {
          context.commit("setIsLoading", false);
          if (errorMessage) {
            context.commit("setMessageFromServer", {
              message: errorMessage + ": " + error,
              isSuccess: false,
            });
          } else {
            context.commit("setMessageFromServer", {
              message:
                "Oops. Something went wrong when retrieving data! " + error,
              isSuccess: false,
            });
          }
          return reject(error);
        });
    });
  }
}

export default new CallService();
