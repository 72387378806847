const dataGetters = {
  GET_USERNAME: "userName",
  IS_LOADING: "isLoading",
  GET_MESSAGE_FROM_SERVER: "getMessageFromServer",
  // GET_LANGUAGE: "getLanguage",
  // GET_ERROR_BASIC: "getErrorBasic",
  GET_TRANSPORT_ORDERS_PENDING: "getTransportOrdersPending",
  GET_TRANSPORT_ORDERS_WAITING_FOR_PICKUP: "getTransportOrdersWaitingForPickup",
  GET_TRANSPORT_ORDERS_IN_DELIVERY: "getTransportOrdersInDelivery",
  GET_TRANSPORT_ORDERS_DELIVERED: "getTransportOrdersDelivered",
  GET_PERMISSIONS: "getPermissions",
  GET_LOGS: "getLogs",
  // GET_PERSON_INFO: "getPersonInfo",
  // GET_EMAIL_CONTENT: "getEmailContent",
  // GET_REPAIR_SHOPS: "getRepairShops",
  // GET_RESULT_MESSAGE: "getResultMessage",
};

export { dataGetters };
