export default {
  msalConfig: {
    auth: {
      clientId: process.env.VUE_APP_MSAL_CLIENT_ID, //"168c01c9-5c41-49f0-9888-60d65d9f3d90", // This is your client ID
      authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY, //"https://kamuxb2c.b2clogin.com/kamuxb2c.onmicrosoft.com/B2C_1_transport_signin", // This is your tenant info
      knownAuthorities: ["https://kamuxtransport.b2clogin.com"], // new
      redirecturl: process.env.VUE_APP_REDIRECT,
      postLogoutRedirectUri: process.env.VUE_APP_REDIRECT,
      navigateToLoginRequestUrl: false,
      request: {
        scopes: ["openid", "offline_access", "profile"],
      },
      validateAuthority: false,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  graphConfig: {
    graphendpoint: "https://graph.microsoft.com/beta/me",
    graphScopes: {
      scopes: [
        "https://kamuxtransport.onmicrosoft.com/transport/api/User.Read",
      ], //TransportService
    },
  },
  transportApiManagementConfig: {
    apiScopes: {
      scopes: [
        "https://kamuxtransport.onmicrosoft.com/306b5a1b-5ec2-47f9-9107-2e323526ada4/User.Secret",
      ],
    },
  },
};
