<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Edit dates for order ({{ editedItem.RegNo }})</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-menu
                ref="dateMenu1"
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datePickup"
                    label="Pickup Date"
                    hint="DD.MM.YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(datePickup)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateParsedPickup"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu1 = false"
                ></v-date-picker>
                <!-- <v-date-picker
                  v-model="dateParsedPickup"
                  :min="minAllowedDatePickup"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu1 = false"
                ></v-date-picker> -->
              </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
              <v-menu
                ref="dateMenu2"
                v-model="dateMenu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateDelivery"
                    label="Delivery Date"
                    hint="DD.MM.YYYY format"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateDelivery)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateParsedDelivery"
                  :min="minAllowedDateDelivery"
                  :first-day-of-week="1"
                  locale="fi-FI"
                  no-title
                  @input="dateMenu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-container class="mt-10">
            <!-- <v-row>
              <v-col cols="12" lg="6">
                <v-card class="mr-1 ml-1" elevation="1">
                  <v-card-title class="text-subtitle-1"
                    >Pickup location details</v-card-title
                  >
                  <v-card-text>
                    {{ editedItem.PickupAddress }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card class="mr-1 ml-1" elevation="1">
                  <v-card-title class="text-subtitle-1"
                    >Delivery location details</v-card-title
                  >
                  <v-card-text>
                    {{ editedItem.DestinationAddress }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card class="md-2" elevation="1">
                  <v-card-title class="text-subtitle-1"
                    >Car Details</v-card-title
                  >
                  <v-card-text>
                    <b>Regno:</b> {{ editedItem.RegNo }}<br />
                    <b>Brand:</b> {{ editedItem.Brand }}<br />
                    <b>Model:</b> {{ editedItem.Model }}<br />
                    <b>Vin:</b> {{ editedItem.Vin }}<br />
                    <b>Orginal regno:</b> *************<br />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row> -->
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="saveSingle"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { helpersMixin } from "@/mixins/mixins.js";

export default {
  mixins: [helpersMixin],
  name: "editOrderSingle",
  props: {
    editedItem: {
      type: Object,
      required: true,
      default() {
        return { message: "Testing" };
      },
    },
    page: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      dialog: true,
      pageInUse: this.page,
      datePickup: "", //this.editedItem.EstimatedPickupTime,
      dateDelivery: "", //this.editedItem.EstimatedDeliveryTime,
      dateParsedPickup: "", //this.parseDate(this.datePickup),
      dateParsedDelivery: "", //this.parseDate(this.dateDelivery),
      dateMenu1: false,
      dateMenu2: false,
      minAllowedDatePickup: this.dateNow(),
      minAllowedDateDelivery: this.dateNow(),
    };
  },
  watch: {
    dateParsedPickup() {
      this.datePickup = this.formatDate(this.dateParsedPickup);
      this.dateDelivery = this.formatDate(this.dateParsedDelivery);
      this.minAllowedDateDelivery = this.parseDate(this.datePickup);
    },
    dateParsedDelivery() {
      this.dateDelivery = this.formatDate(this.dateParsedDelivery);
    },
  },
  created() {
    if (this.page == "waiting") {
      this.datePickup = this.editedItem.EstimatedPickupTime;
      this.dateDelivery = this.editedItem.EstimatedDeliveryTime;
    } else if (this.page == "inDelivery") {
      this.datePickup = this.editedItem.ConfirmedPickupTime;
      this.dateDelivery = this.editedItem.ConfirmedDeliveryTime;
    }
    this.dateParsedPickup = this.parseDate(this.datePickup);
    this.dateParsedDelivery = this.parseDate(this.dateDelivery);
  },
  methods: {
    close() {
      this.$emit("onEditClose");
      this.dialog = false;
    },
    saveSingle() {
      let payload = {
        dateParsedPickup: this.dateParsedPickup,
        dateParsedDelivery: this.dateParsedDelivery,
      };

      this.$emit("onEditSave", payload);
      this.dialog = false;
    },
  },
};
</script>
