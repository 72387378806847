import { PublicClientApplication } from "@azure/msal-browser";
import config from "../config/auth";

export default class AuthService {
  constructor() {
    this.app = new PublicClientApplication(config.msalConfig);
  }

  async loginRedirect() {
    await this.handleRedirect();
    await this.app.loginRedirect(config.msalConfig.auth.request.scopes);
    this.$store.commit("initUserName", this.getUserName());
  }

  async handleRedirect() {
    await this.app
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          this.handleResponse(response, this.app);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  handleResponse(resp, app) {
    if (resp !== null) {
      let username = resp.account.username;
      this.$store.commit("initUserName", username);
    } else {
      const currentAccounts = app.getAllAccounts();
      if (currentAccounts === null) {
        return;
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn("Multiple accounts detected.");
      } else if (currentAccounts.length === 1) {
        let username = currentAccounts[0].username;
        this.$store.commit("initUserName", username);
      }
    }
  }

  signIn() {
    this.app.loginRedirect(config.msalConfig.auth.request.scopes);
  }

  async signOut(username) {
    const logoutRequest = {
      account: this.app.getAccountByUsername(username),
    };
    await this.handleRedirect();
    await this.app.logoutRedirect(logoutRequest);
  }

  getTokenRedirect(request, username) {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account = this.app.getAccountByUsername(username);

    try {
      return this.app.acquireTokenSilent(request);
    } catch (error) {
      console.warn(
        "silent token acquisition fails. acquiring token using redirect"
      );
      if (error instanceof this.app.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.app.acquireTokenRedirect(request);
      } else {
        console.warn(error);
      }
    }
  }

  getIsAuthenticated() {
    let result = false;
    let accounts = [];
    accounts = this.app.getAllAccounts();

    if (accounts.length > 0) {
      result = true;
    }

    return result;
  }

  getUserName() {
    let accounts = this.getAllAccounts();
    let username = accounts[0].username;
    return username;
  }

  getUserAccount() {
    let accounts = this.getAllAccounts();
    let account = accounts[0];
    return account;
  }

  getAllAccounts() {
    return this.app.getAllAccounts();
  }

  getApiManagementToken() {
    const account = this.app.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: config.transportApiManagementConfig.apiScopes.scopes,
      account: account,
    };

    return this.app.acquireTokenSilent(accessTokenRequest).then(
      (accessToken) => {
        return accessToken;
      },
      (error) => {
        console.log(error);
        return this.app.acquireTokenPopup(accessTokenRequest).then(
          (accessToken) => {
            return accessToken;
          },
          (err) => {
            console.error(err + " : " + error);
            return err + " : " + error;
          }
        );
      }
    );
  }

  getGraphToken() {
    // Not really working with B2C
    const account = this.getAllAccounts()[0];

    const accessTokenRequest = {
      scopes: config.graphConfig.graphScopes.scopes,
      account: account,
    };

    return this.app.acquireTokenSilent(accessTokenRequest).then(
      (accessToken) => {
        return accessToken.accessToken;
      },
      (error) => {
        return this.app.acquireTokenPopup(accessTokenRequest).then(
          (accessToken) => {
            return accessToken.accessToken;
          },
          (err) => {
            console.error(err + " : " + error);
            return err + " : " + error;
          }
        );
      }
    );
  }

  getGraphUserInfo(token) {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    });
    const options = {
      headers,
    };
    return fetch(`${config.graphConfig.graphendpoint}`, options)
      .then((response) => response.json())
      .catch((response) => {
        throw new Error(response.text());
      });
  }

  async getGraphUser() {
    return this.getGraphUserInfo(await this.getGraphToken());
  }
}
