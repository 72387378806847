import { dataActions } from "@/store/actions";
import { dataGetters } from "@/store/getters";
import DataService from "@/service/data-service";
import CallService from "@/service/call-service";

export const state = {
  isLoading: false,
  userName: "",
  token: "",
  transportOrdersPending: [],
  transportOrdersWaitingForPickup: [],
  transportOrdersPickedup: [],
  transportOrdersInDelivery: [],

  transportOrdersDelivered: [],
  transportLogs: [],
  messageFromServer: { message: "", isSuccess: false },
  permissions: "",
};

export const actions = {
  async [dataActions.GET_TRANSPORT_ORDERS](context, service) {
    const data = await CallService.CallServiceGet(
      DataService.getTransportOrders(service),
      context
    );
    if (service.service == 1) {
      context.commit("setTransportOrdersPending", data);
    } else if (service.service == 3) {
      context.commit("setTransportOrdersWaitingForPickup", data);
    } else if (service.service == 4) {
      context.commit("setTransportOrdersInDelivery", data);
      // } else if (service.service == "GetDeliveredByTransporter") {
      //   context.commit("setTransportOrdersDeliveredByTransporter", data);
    } else if (service.service == 5) {
      context.commit("setTransportOrdersDelivered", data);
    }
  },
  async [dataActions.GET_LOGS](context, payload) {
    // let successMessage = sendOfferData.successMessage;
    // let errorMessage = sendOfferData.errorMessage;
    const data = await CallService.CallServiceGet(
      DataService.getLogs(payload),
      context
    );
    context.commit("setLogs", data.value);
  },
  async [dataActions.UPDATE_TRANSPORT_ORDERS](context, payload) {
    // let successMessage = sendOfferData.successMessage;
    // let errorMessage = sendOfferData.errorMessage;
    await CallService.CallServiceAction(
      DataService.updateTransportOrders(payload),
      context
      // successMessage,
      // errorMessage
    );
  },
  async [dataActions.UPDATE_TRANSPORT_ORDERS_KMS](context, payload) {
    // let successMessage = sendOfferData.successMessage;
    // let errorMessage = sendOfferData.errorMessage;
    await CallService.CallServiceAction(
      DataService.updateTransportOrdersKMS(payload),
      context
      // successMessage,
      // errorMessage
    );
  },
  async [dataActions.GET_PERMISSIONS](context, payload) {
    // let successMessage = sendOfferData.successMessage;
    // let errorMessage = sendOfferData.errorMessage;
    const data = await CallService.CallServiceGet(
      DataService.getPermissions(payload),
      context
    );
    context.commit("setPermissions", data.transport_company);
  },
};

export const getters = {
  [dataGetters.GET_TRANSPORT_ORDERS_PENDING](state) {
    return state.transportOrdersPending;
  },
  [dataGetters.GET_TRANSPORT_ORDERS_WAITING_FOR_PICKUP](state) {
    return state.transportOrdersWaitingForPickup;
  },
  [dataGetters.GET_TRANSPORT_ORDERS_IN_DELIVERY](state) {
    return state.transportOrdersInDelivery;
  },
  [dataGetters.GET_TRANSPORT_ORDERS_DELIVERED](state) {
    return state.transportOrdersDelivered;
  },
  [dataGetters.GET_USERNAME](state) {
    return state.userName;
  },
  [dataGetters.IS_LOADING](state) {
    return state.isLoading;
  },
  [dataGetters.GET_MESSAGE_FROM_SERVER](state) {
    return state.messageFromServer;
  },
  [dataGetters.GET_PERMISSIONS](state) {
    return state.permissions;
  },
  [dataGetters.GET_LOGS](state) {
    return state.transportLogs;
  },
};

export const mutations = {
  setMessageFromServer(state, message) {
    state.messageFromServer = message;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  initToken(state, token) {
    state.token = token;
  },
  initUserName(state, userName) {
    state.userName = userName;
  },
  setTransportOrdersPending(state, data) {
    state.transportOrdersPending = data;
  },
  setTransportOrdersWaitingForPickup(state, data) {
    state.transportOrdersWaitingForPickup = data;
  },
  setTransportOrdersInDelivery(state, data) {
    state.transportOrdersInDelivery = data;
  },
  setTransportOrdersDelivered(state, data) {
    state.transportOrdersDelivered = data;
  },
  setPermissions(state, data) {
    state.permissions = data;
  },
  setLogs(state, data) {
    state.transportLogs = data;
  },
};

export default {
  namespaced: false,
  state,
  actions,
  getters,
  mutations,
};
